/** @format */
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
export default {
  L1001: "登录",
  L1002: "注册",
  L1003: "记住我",
  L1004: "忘记密码？",
  L1005: "用户名 / 电子邮件 / 电话号码",
  L1006: "请输入您的密码",
  L1007: "还没有账号？",
  L1008: "重设密码",
  L1009: "请输入您的电话号码",
  L1010: "输入验证码",
  L1011: "提交",
  L1012: "电子邮件",
  L1013: "手机号码",
  L1014: "输入电子邮件",
  L1015: "电话号码",
  L1016: "已有账号？",
  L1017: "验证码",
  L1018: "登出",
  L1019: "返回首页",
  L1020: "输入用户名",
  L1021: "输入 OID",
  L1022: "请输入一个有效的电子邮件地址",
  L1023: "热门推荐",
  L1024: "老虎机",
  L1025: "休闲游戏",
  L1026: "密码",
  L1027: "确认",
  L1028: "确认密码",
  L1029: "注册",
  L1030: "请检查密码是否匹配",
  L1031: "电话",
  L1032: "验证码",
  L1033: "输入验证码",
  L1034: "返回",
  L1035: "发送",

  C1001: "中文",
  C1002: "英文",
  C1003: "巴西葡萄牙语",

  H0001: "菜单",
  H0002: "赌场",
  H0003: "首页",
  H0004: "奖金",
  H0005: "VIP",
  H0006: "我的游戏",
  H0007: "最近",
  H0008: "会员",
  H0009: "奖励",
  H0010: "手机号码不正确",
  H0011: "必须为 {min} 到 {max} 个字符长，可包含数字、小写字母和大写字母",
  H0012: "网络连接已断开，请检查",
  H0013: "促销",
  H0014: "充值",
  H0015: "分享",
  H0016: "个人资料",
  H0017: "举报",
  H0018: "彩票",
  H0019: "奖金",
  H0020: "VIP",
  H0021: "活动",
  H0022: "提现",
  H0023: "特权",
  H0024: "记录",
  H0025: "规则",
  H0026: "移动端推广",
  H0027: "分享图片",
  H0028: "分享链接",
  H0029: "在社交媒体上分享",
  H0030: "保存图片",
  H0031: "复制链接",
  H0032: "安装",
  H0033: "保存，轻松投注！",
  H0034: "保存",
  H0035: "签到",
  H0036: "电子邮件",
  H0037: "活动",
  H0038: "提现",
  H0039: "购物",
  H0040: "电报",
  H0041: "100% 奖金",
  H0042: "50% 退款",
  H0043: "免费赚美元",
  H0044: "下载应用程序",
  H0045: "添加到主屏幕",
  H0046: "在线客服",

  E0001: "找不到此页面",
  E0002: "维护中",

  ...zhCn,
};
