/** @format */
import en from "element-plus/dist/locale/en.mjs";
export default {
  L1001: "Sign In",
  L1002: "Sign Up",
  L1003: "Remember me",
  L1004: "Forgot Password?",
  L1005: "Account / Email / Phone No.",
  L1006: "Please enter your password",
  L1007: "Don't have an account?",
  L1008: "Reset Password",
  L1009: "Please enter your Phone Number",
  L1010: "Enter Code",
  L1011: "Submit",
  L1012: "Email",
  L1013: "Mobile No",
  L1014: "Enter Email",
  L1015: "Phone Number",
  L1016: "Already have account?",
  L1017: "Code",
  L1018: "Sign Out",
  L1019: "Back Home",
  L1020: "Enter UserName",
  L1021: "Enter OID",
  L1022: "Please enter a valid email address",
  L1023: "Popular",
  L1024: "Spirit",
  L1025: "Casual game",
  L1026: "Password",
  L1027: "Confirm",
  L1028: "Confirm Password",
  L1029: "Register",
  L1030: "Please check if the passwords match",
  L1031: "Telephone",
  L1032: "Captcha",
  L1033: "Enter the verification code",
  L1034: "Back",
  L1035: "Send",

  C1001: "Chinese",
  C1002: "English",
  C1003: "Brazil Portugal",

  H0001: "Menu",
  H0002: "Casino",
  H0003: "Home",
  H0004: "Bonus",
  H0005: "VIP",
  H0006: "Mygame",
  H0007: "Recent",
  H0008: "Affiliate",
  H0009: "Rewards",
  H0010: "Incorrect mobile number",
  H0011: `{min} to {max} characters long,number or lowercase and uppercase letters`,
  H0012: "The network has been disconnected, please check",
  H0013: "Promotion",
  H0014: "Charge",
  H0015: "Share",
  H0016: "Profile",
  H0017: "Report",
  H0018: "Lottery",
  H0019: "Bonus",
  H0020: "VIP",
  H0021: "Activity",
  H0022: "Withdraw",
  H0023: "Privilege",
  H0024: "Records",
  H0025: "Rules",
  H0026: "Mobile promotion",
  H0027: "Share image",
  H0028: "Share link",
  H0029: "Share on social media",
  H0030: "Save the image",
  H0031: "Copy link",
  H0032: "Install",
  H0033: "Save to have fun, bet with ease!",
  H0034: "Save",
  H0035: "Check-in",
  H0036: "E-mail",
  H0037: "Atividade",
  H0038: "Saque",
  H0039: "Shopping",
  H0040: "Telegram",
  H0041: "100% Bonus",
  H0042: "50% refund",
  H0043: "Earn $ for Free",
  H0044: "Download the app",
  H0045: "Add to home screen",
  H0046: "Live Chat",
  H0047: "The phone number has {length} digits",
  H0048: "Wrong phone number",

  E0001: "Can't find this page",
  E0002: "Maintenance is in progress.",

  ...en,
};
