/** @format */
import ptBr from "element-plus/dist/locale/pt-br.mjs";
export default {
  L1001: "Entrar",
  L1002: "Inscrever-se",
  L1003: "Lembrar-me",
  L1004: "Esqueceu a senha?",
  L1005: "Conta / E-mail / Número de telefone",
  L1006: "Por favor, insira sua senha",
  L1007: "Não tem uma conta?",
  L1008: "Redefinir senha",
  L1009: "Por favor, insira seu número de telefone",
  L1010: "Inserir código",
  L1011: "Enviar",
  L1012: "E-mail",
  L1013: "Número de telefone móvel",
  L1014: "Inserir e-mail",
  L1015: "Número de telefone",
  L1016: "Já tem uma conta?",
  L1017: "Código",
  L1018: "Sair",
  L1019: "Voltar para casa",
  L1020: "Inserir nome de usuário",
  L1021: "Inserir OID",
  L1022: "Por favor, insira um endereço de e-mail válido",
  L1023: "Popular",
  L1024: "Spirit",
  L1025: "Jogo casual",
  L1026: "Senha",
  L1027: "Confirmar",
  L1028: "Confirmar senha",
  L1029: "Registrar",
  L1030: "Por favor, verifique se as senhas coincidem",
  L1031: "Telefone",
  L1032: "Captcha",
  L1033: "Inserir o código de verificação",
  L1034: "Voltar",
  L1035: "Enviar",
  L1036: "Depósito",

  C1001: "Chinês",
  C1002: "Inglês",
  C1003: "Português do Brasil",

  H0001: "Menu",
  H0002: "Cassino",
  H0003: "Casa",
  H0004: "Bônus",
  H0005: "VIP",
  H0006: "Meus jogos",
  H0007: "Recentes",
  H0008: "Afiliado",
  H0009: "Recompensas",
  H0010: "Número de celular incorreto",
  H0011: "{min} a {max} caracteres de comprimento, número ou letras minúsculas e maiúsculas",
  H0012: "A rede foi desconectada, por favor, verifique",
  H0013: "Promoção",
  H0014: "Recarregar",
  H0015: "Compartilhar",
  H0016: "Perfil",
  H0017: "Relatório",
  H0018: "Lotérica",
  H0019: "Bônus",
  H0020: "VIP",
  H0021: "Atividade",
  H0022: "Sacar",
  H0023: "Privilégio",
  H0024: "Registros",
  H0025: "Regras",
  H0026: "Promoção móvel",
  H0027: "Compartilhar imagem",
  H0028: "Compartilhar link",
  H0029: "Compartilhar nas redes sociais",
  H0030: "Salvar a imagem",
  H0031: "Copiar link",
  H0032: "Instalar",
  H0033: "Salve para se divertir, aposte com facilidade!",
  H0034: "Salvar",
  H0035: "Check-in",
  H0036: "E-mail",
  H0037: "Atividade",
  H0038: "Saque",
  H0039: "Compras",
  H0040: "Telegrama",
  H0041: "100% Bônus",
  H0042: "50% Reembolso",
  H0043: "Ganhe $ de graça",
  H0044: "ΒΑΙΧΕ Ο ΑPP",
  H0045: "Adicionar à tela inicial",
  H0046: "Suporte ao vivo",
  H0047: "O número de celular tem {length} dígitos",
  H0048: "Número de telefone errado",
  H0049: "Retirada",
  H0050: "Registo",
  H0051: "Sorteio de mesa giratória",
  H0052: "Convite para casa",
  H0053: "Detalhes da promoção",
  H0054: "Serviço ao cliente de jogos",
  H0055: "Selecção da língua",
  H0056: "Português",

  E0001: "Não é possível encontrar esta página",
  E0002: "Manutenção em andamento",

  ...ptBr,
};
